<template>
  <div>
    <el-page-header
      icon="el-icon-arrow-left"
      content="查看跟读任务"
      @back="goBack"
    ></el-page-header>
    <div v-loading="loading">
      <el-form class="follow-form" label-width="140px">
        <el-form-item label="跟读中文：">
          <div>{{ followData.sentence_name }}</div>
        </el-form-item>
        <el-form-item label="跟读英文：">
          <div>{{ followData.sentence }}</div>
        </el-form-item>
        <el-form-item label="句子连读：">
          <div>{{ followData.sentence_content }}</div>
        </el-form-item>
        <el-form-item label="示范音频：">
          <div style="display: flex">
            <audio
              style="height: 40px"
              :src="followData.audio"
              controls="controls"
            ></audio>
          </div>
        </el-form-item>
        <el-form-item label="所属阶段：">
          <div>{{ followData.level_name }}</div>
        </el-form-item>
        <el-form-item label="所属主题：">
          <div>{{ followData.program_name }}</div>
        </el-form-item>
        <el-form-item label="是否备选：">
          <div>{{ followData.is_alternative === "1" ? "是" : "否" }}</div>
        </el-form-item>
        <el-form-item v-if="followData.image && type === '1'" label="配图：">
          <el-image
            style="width: 100px"
            :src="followData.image"
            fit="contain"
          ></el-image>
        </el-form-item>
        <el-form-item
          v-if="followData.share_img && type === '1'"
          label="分享图："
        >
          <el-image
            style="width: 100px"
            :src="followData.share_img"
            fit="contain"
          ></el-image>
        </el-form-item>
        <el-form-item
          v-if="followData.onlinetime && type === '1'"
          label="上线时间："
        >
          <div>{{ followData.onlinetime }}</div>
        </el-form-item>
        <el-form-item label="状态：">
          <div>{{ followData.status }}</div>
        </el-form-item>
        <el-form-item
          v-if="followData.status === '审核不通过'"
          label="不通过原因："
        >
          <div>{{ followData.audit_remarks }}</div>
        </el-form-item>
        <el-form-item v-if="type === '1' && followData.status === '审核通过'">
          <el-button type="primary" @click="onSubmit">上线</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getFollowTaskInfo, onlineFollowTask } from "@/api/apiList/content-api";
export default {
  data() {
    return {
      loading: true,
      followData: {},
      onlinetime: "",
      type: "",
      id: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    onSubmit() {
      this.$confirm("确定要将此条任务上线吗？上线后用户可进行跟读？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: this.id,
          };
          onlineFollowTask(obj).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handData(this.id);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消上线",
          });
        });
    },
    handData(e) {
      let obj = {
        id: e,
      };
      getFollowTaskInfo(obj).then((res) => {
        if (res.result === "200") {
          switch (res.data.status) {
            case 0:
              res.data.status = "待审核";
              break;
            case 1:
              res.data.status = "审核不通过";
              break;
            case 2:
              res.data.status = "审核通过";
              break;
            case 3:
              res.data.status = "已上线";
              break;
          }
          this.followData = res.data;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.handData(this.id);
  },
};
</script>

<style lang="scss" scoped>
.follow-form {
  margin-top: 50px;
}
</style>
